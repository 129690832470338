<template>
  <router-view></router-view>
</template>

<script>
import router from '../../router'
import { mapGetters } from 'vuex'

export default {
  name: 'User',
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  mounted() {
    if (!this.user.data) {
      router.push('/login')
    }
    else {
      this.user.show_admin = false
    }
  },
}
</script>
